import { template as template_38aff1e197c64063896d70c06ea33885 } from "@ember/template-compiler";
const FKText = template_38aff1e197c64063896d70c06ea33885(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
