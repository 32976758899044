import { template as template_8e583c9be9d94e3abb7a9a47e40c28d1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_8e583c9be9d94e3abb7a9a47e40c28d1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
